.commonHeader {
  padding: 0 0.3rem;
  background-repeat: no-repeat;
  background-position: center top;
  z-index: 99;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}
.commonHeader .head {
  position: relative;
  height: 0.88rem;
  line-height: 0.88rem;
}
.commonHeader .left {
  position: absolute;
  left: 0;
  top: 0.01rem;
}
.commonHeader .left .icon {
  width: 0.6rem;
  height: 0.88rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.commonHeader .left img {
  width: 0.19rem;
  height: 0.33rem;
}
.commonHeader .title {
  font-family: PingFang SC;
  color: #FFFFFF;
  font-size: 0.32rem;
  font-weight: 600;
  text-align: center;
  text-transform: capitalize;
}
.commonHeader .title span {
  font-weight: 600;
}
.commonHeader .right {
  position: absolute;
  right: 0;
  top: 0;
  height: 0.88rem;
  display: flex;
  align-items: center;
}
.commonHeader .right .text {
  color: #FFFFFF;
  font-size: 0.28rem;
}
.commonHeader .right img {
  width: 0.4rem;
  height: 0.4rem;
}
